<template>
  <div class="main">
    <div style="display: flex;padding: 10px">
      <img
          v-if="user.headUrl"
          width="60"
          height="60"
          style="border-radius: 50%"
          v-lazyload :data-src="user.headUrl"
      />
      <div class="des">
        <div class="des-name">{{ user.account }}</div>
        <div style="display: flex;padding-top: 7px">
          <van-icon color="#06f" name="wechat-pay"/>
          <span @click="$router.push('/edit')" class="author-des">编辑资料</span>
        </div>
      </div>
      <div class="top-right">
        <div class="top-right-item">
          <p>获赞</p>
          <p class="color-gray">{{info.zan}}</p>
        </div>
        <div class="top-right-item">
          <p>被关注</p>
          <p class="color-gray">{{info.beConcerned}}</p>
        </div>
        <div class="top-right-item">
          <p>关注</p>
          <p class="color-gray">{{info.concerned}}</p>
        </div>
      </div>
    </div>
    <div class="card" style="padding: 20px 0">
<!--            <div class="card-title">我的数据</div>-->
            <van-row style="text-align: center">
              <van-col span="12" class="card-item">{{user.subAmount}}
                <div class="card-d1">余额</div>
              </van-col>
<!--              <van-col span="8" class="card-item">{{user.nickName}}
                <div class="card-d1">金币</div>
              </van-col>-->
              <van-col span="12" class="card-item">{{user.certification?'未认证':'已认证'}}
                <div class="card-d1">认证状态</div>
              </van-col>
            </van-row>
    </div>
    <div class="card">
      <!--      <div class="card-title">我的数据</div>
            <van-row style="text-align: center">
              <van-col span="8" class="card-item">{{user.subAmount}}
                <div class="card-d1">订阅金额</div>
              </van-col>
              <van-col span="8" class="card-item">{{user.nickName}}
                <div class="card-d1">昵称</div>
              </van-col>
              <van-col span="8" class="card-item">{{user.certification?'未认证':'已认证'}}
                <div class="card-d1">认证状态</div>
              </van-col>
            </van-row>-->
      <div class="link-box">
        <div class="link-row">
          <div @click="$router.push('/myJob')" class="link-item">
            <p class="icon">
              <van-icon size="30" name="sign" />
            </p>
            <p class="link-text">我的任务</p>
          </div>
          <div @click="$router.push('/collect')" class="link-item">
            <p class="icon">
              <van-icon size="30" name="star-o"/>
            </p>
            <p class="link-text">收藏</p>
          </div>
          <div @click="$router.push('/subscription')" class="link-item">
            <p class="icon">
              <van-icon size="30" name="coupon-o"/>
            </p>
            <p class="link-text">订阅</p>
          </div>
          <div @click="$router.push('/message')" class="link-item">
            <p class="icon">
              <van-icon size="30" name="volume-o"/>
            </p>
            <p class="link-text">通知</p>
          </div>
        </div>
        <div class="link-row">
          <div @click="$router.push('/myJob')" class="link-item">
            <p class="icon">
              <van-icon size="30" name="sign" />
            </p>
            <p class="link-text">我的回复</p>
          </div>
          <div @click="$router.push('/cardList')" class="link-item">
            <p class="icon">
              <van-icon size="30" name="credit-pay"/>
            </p>
            <p class="link-text">银行卡</p>
          </div>
          <div @click="$router.push('/cardList')" class="link-item">
            <p class="icon">
              <van-icon size="30" name="phone-o"/>
            </p>
            <p class="link-text">绑定手机</p>
          </div>
          <div @click="$router.push('/withdrawal')" class="link-item">
            <p class="icon">
              <van-icon size="30" name="cash-on-deliver" />
            </p>
            <p class="link-text">提现</p>
          </div>
        </div>
        <div class="link-row" v-if="!user.certification">
          <div @click="$router.push('/verify')" class="link-item">
            <p class="icon">
              <van-icon size="30" name="sign" />
            </p>
            <p class="link-text">认证</p>
          </div>
        </div>
      </div>
    </div>
    <van-button @click="logout" style="margin-top: 20px"  type="default" block>退出</van-button>
    <img src="../assets/me.png" alt="" style="width: 100%;margin-top: 100px">
  </div>
</template>

<script>
import {BASE_RUL} from "@/utils/request";
import {getUserTotal} from "@/api/user";
import Cookies from "js-cookie";

export default {

  data() {
    return {
      base: BASE_RUL,
      info:{}
    }
  },
  created() {
    getUserTotal({}).then((res)=>{
      if (res.code==0) {
        this.info=res.result
      }
    })
  },
  computed: {
    user: function () {
      return this.$store.state.userInfo || {}
    }
  },
  methods:{
    logout(){
      Cookies.set('accessToken',null, {expires: 365})
      Cookies.set('userInfo',null,365)
      this.$router.push('/login')
    }
  }

}
</script>

<style scoped>
.card {
  width: 100%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 20px;
  letter-spacing: 1px;
}

.card-title {
  font-size: 12px;
  font-weight: bold;
  color: rgba(0, 0, 0, .7);
  padding-left: 25px;
  padding-top: 12px;
  padding-bottom: 10px;
}

.card-item {
  font-size: 18px;
}

.card-d1 {
  font-size: 11px;
  padding-top: 5px;
  color: rgba(0, 0, 0, .8);
}

.main {
  padding: 20px;
}

.des {
  padding-left: 20px;
  letter-spacing: 1px;
  padding-top: 6px;
}

.des-name {
  font-size: 18px;
  padding-left: 3px;
}

.author-des {
  font-size: 12px;
  color: rgba(0, 0, 0, .6);
  padding-left: 2px;
}

.link-box {
  padding: 0 16px;
}

.link-box .link-row {
  display: flex;
  justify-content: space-between;
}

.link-box .link-row .link-item {
  text-align: center;
  width: 25%;
}

.link-box .link-row .link-item .icon {
  margin-bottom: 0;
}

.link-box .link-row .link-item .link-text {
  margin-top: 10px;
}

.top-right{
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding-top: 6px;
  padding-left: 10px;
}
.top-right p{
  text-align: center;
  margin: 0;
  margin-bottom:7px;
}
.color-gray{
  color: #ababab;
}
</style>
